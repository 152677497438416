<template>
  <footer class="pt-5 my-5 text-muted border-top">
  </footer>
</template>

<script>
export default {
name: "PlayerFooter"
}
</script>

<style scoped>

</style>