<template>
<header class="d-flex align-items-center pb-3 mb-5 border-bottom">
    <a href="https://www.burckhardtcompression.com/" target="_blank"
       class="d-flex align-items-bottom text-dark text-decoration-none">
      <img src="@/assets/brand/sfx_logo_rgb.png" style="width:200px">
    </a>
    <h1 style="margin-right:1px;vertical-align: bottom">vStage Basics - Starter Tutorial</h1><br/>
  </header>
</template>

<script>
export default {
  name: "PlayerHeader"
}
</script>

<style scoped>

</style>