<template>
  <div id="app">

    <div class="col-lg-8 col-sm-12 mx-auto p-3 py-md-5">
      <player-header />
      <player />
      <player-footer />
    </div>
  </div>
</template>

<script>
import PlayerHeader from "@/components/PlayerHeader";
import PlayerFooter from "@/components/PlayerFooter";
import Player from "@/components/Player";
export default {
  name: 'App',
  components: {
    PlayerHeader,
    PlayerFooter,
    Player
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
